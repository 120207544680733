import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source', 'form']

  connect () {
    this.sourceTargets.forEach((source) => {
      source.addEventListener('change', this.handleChange.bind(this))
    })
  }

  handleChange (event) {
    const syncKey = event.target.dataset.checkboxSyncKey
    const isChecked = event.target.checked

    this.syncCheckboxes(syncKey, isChecked)
  }

  syncCheckboxes (syncKey, isChecked) {
    if (!syncKey) { return; }

    this.sourceTargets.forEach((source) => {
      if (source.dataset.checkboxSyncKey === syncKey) {
        source.checked = isChecked
      }
    })
  }
}
