import { Controller } from '@hotwired/stimulus';
import { Fancybox } from "@fancyapps/ui";

export default class extends Controller {
  static values = {
    url: String
  }

  show(event) {
    event.preventDefault()

    fetch(this.urlValue).
      then(response => response.json()).
      then(json => new Fancybox(
        json,
        {
          Html: {
            video: {
              autoplay: false,
            },
          },
        }
      ))
  }
}
