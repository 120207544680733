import { Controller } from '@hotwired/stimulus';

// This controller is used to toggle the height of content in a container. By clicking
// on the toggle button, the content will expand or collapse.
export default class extends Controller {
  static targets = ['collapse', 'expand', 'toggle']
  static values = {
    initialHeight: Number
  }

  connect () {
    if (this.element.clientHeight > this.initialHeightValue) {
      // Element is higher than we want it to be, enabling collapse
      this.element.style.height = `${this.initialHeightValue}px`
      this.element.style.transition = 'height 0.5s ease'
      this.element.style.overflow = 'hidden'
    } else {
      // Element is not higher than we want it to be, removing toggle
      if (this.hasToggleTarget) { this.hideElement(this.toggleTarget) }
    }
  }

  hideElement(element) {
    element.classList.add('d-none')
  }

  setHeight(height) {
    this.element.style.height = height + 'px'
  }

  showElement(element) {
    element.classList.remove('d-none')
  }

  toggle () {
    const maxHeight = this.element.scrollHeight
    const currentHeight = parseFloat(getComputedStyle(this.element).height)

    if (currentHeight < maxHeight) {
      this.showElement(this.collapseTarget)
      this.hideElement(this.expandTarget)
      this.setHeight(maxHeight)
    } else {
      this.showElement(this.expandTarget)
      this.hideElement(this.collapseTarget)
      this.setHeight(this.initialHeightValue)
    }
  }
}
